<template>
  <div class="filter-wrapper">
    <button
      class="button outline primary sm"
      v-click-outside="vcoConfig"
      v-on:click="toggle">
      <filter-variant-icon/>
      {{ $t('filter.title') }}
    </button>
    <div
      class="filter-content"
      ref="filterBox"
      v-if="isVisible">
      <div class="grid-y">
        <div
          class="cell"
          v-if="showCompanies && display.includes('company')"
        >
          <multiselect
            track-by="id"
            label="name"
            v-model="currentCompany"
            v-bind:allow-empty="false"
            v-bind:show-labels="false"
            v-bind:placeholder="$t('filter.company')"
            v-bind:options="companies">
            <template slot="noResult">{{ $t('multiselect.no-result') }}</template>
            <template slot="noOptions">{{ $t('multiselect.no-options') }}</template>
          </multiselect>
        </div>
        <div class="cell" v-if="display.includes('campaign')">
          <multiselect
            track-by="id"
            label="name"
            v-model="currentCampaign"
            v-bind:allow-empty="false"
            v-bind:show-labels="false"
            v-bind:placeholder="$t('filter.campaign')"
            v-bind:options="campaigns">
            <template slot="noResult">{{ $t('multiselect.no-result') }}</template>
            <template slot="noOptions">{{ $t('multiselect.no-options') }}</template>
          </multiselect>
        </div>
        <div class="cell" v-if="display.includes('channel')">
          <multiselect
            track-by="id"
            label="name"
            v-model="currentChannel"
            v-bind:allow-empty="false"
            v-bind:show-labels="false"
            v-bind:placeholder="$t('filter.channel')"
            v-bind:options="channels">
            <template slot="noResult">{{ $t('multiselect.no-result') }}</template>
            <template slot="noOptions">{{ $t('multiselect.no-options') }}</template>
          </multiselect>
        </div>
        <div class="cell" v-if="display.includes('agent')">
          <multiselect
            track-by="id"
            label="name"
            v-model="currentAgents"
            v-bind:options="agents"
            v-bind:searchable="true"
            v-bind:close-on-select="false"
            v-bind:show-labels="false"
            v-bind:multiple="true"
            v-bind:placeholder="$t('filter.agent')"
            v-bind:limit-text="filterLimit"
            v-bind:limit="1">
            <template slot="noResult">{{ $t('multiselect.no-result') }}</template>
            <template slot="noOptions">{{ $t('multiselect.no-options') }}</template>
          </multiselect>
        </div>
        <div class="cell" v-if="display.includes('date')">
          <input-date
            v-bind:startDate="currentStartDate"
            v-bind:endDate="currentEndDate"
            v-on:update="rangeChanged"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import gql from 'graphql-tag';
import fullCompaniesGql from '@/graphql/queries/fullCompanies.gql';

export default {
  components: {
    InputDate: () => import('@/components/InputDate.vue'),
    Multiselect: () => import('vue-multiselect'),
  },
  props: {
    display: {
      type: Array,
      required: false,
      default: () => ['company', 'campaign', 'agent', 'date'],
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      currentCompany: null,
      currentCampaign: null,
      currentChannel: null,
      currentAgents: [],
      currentStartDate: this.$moment()
        .subtract(7, 'days')
        .toDate(),
      currentEndDate: this.$moment()
        .subtract(1, 'days')
        .toDate(),
      vcoConfig: {
        handler: this.onClose,
        middleware: this.middleware,
        events: ['click'],
      },
      isVisible: false,
    };
  },
  computed: {
    companies() {
      if (this.fullCompanies) {
        return this.fullCompanies.map(company => ({
          id: parseInt(company.id, 10),
          name: company.name,
        }));
      }

      return [];
    },
    showCompanies() {
      return this.companies.length > 1;
    },
    campaigns() {
      const company = this.getCompanyById(this.currentCompanyId);

      return company !== undefined ? company.campaigns.map(campaign => ({
        id: parseInt(campaign.id, 10),
        name: campaign.name,
      })) : [];
    },
    channels() {
      const company = this.getCompanyById(this.currentCompanyId);

      if (company !== undefined) {
        const campaign = company.campaigns.find(
          c => parseInt(this.currentCampaignId, 10) === parseInt(c.id, 10),
        );

        return campaign.channels.map(channel => ({
          id: parseInt(channel.id, 10),
          name: channel.name,
        }));
      }

      return [];
    },
    agents() {
      const company = this.getCompanyById(this.currentCompanyId);

      if (company !== undefined) {
        const agents = company.users;
        const campaign = company.campaigns.find(
          c => parseInt(this.currentCampaignId, 10) === parseInt(c.id, 10),
        );
        const campaignAgents = campaign !== undefined ? campaign.users : [];

        return agents.filter(a => campaignAgents.includes(parseInt(a.id, 10)));
      }

      return [];
    },
    currentCompanyId() {
      return this.currentCompany ? this.currentCompany.id : null;
    },
    currentCampaignId() {
      return this.currentCampaign ? this.currentCampaign.id : null;
    },
    currentChannelId() {
      return this.currentChannel ? this.currentChannel.id : null;
    },
  },
  apollo: {
    fullCompanies: {
      query: gql`${fullCompaniesGql}`,
      fetchPolicy: 'no-cache',
    },
  },
  watch: {
    companies(newValue) {
      if (newValue.length > 0) {
        this.autoSelectCompany(newValue);
      }
    },
    campaigns(newValue) {
      if (newValue.length > 0) {
        if (localStorage.currentCampaign
          && this.campaignIsSelectable(newValue, JSON.parse(localStorage.currentCampaign))) {
          this.currentCampaign = JSON.parse(localStorage.currentCampaign);
        } else {
          const [firstCampaign] = newValue;
          this.currentCampaign = firstCampaign;
        }
      }
    },
    channels(newValue) {
      if (newValue.length > 0) {
        if (localStorage.currentChannel
          && this.channelIsSelectable(newValue, JSON.parse(localStorage.currentChannel))) {
          this.currentChannel = JSON.parse(localStorage.currentChannel);
        } else {
          const [firstChannel] = newValue;
          this.currentChannel = firstChannel;
        }
      }
    },
    currentCompany(newValue) {
      localStorage.currentCompany = JSON.stringify(newValue);
    },
    currentCampaign(newValue) {
      this.resetValues();
      localStorage.currentCampaign = JSON.stringify(newValue);
      this.$emit('change', this.getFilters());
    },
    currentChannel(newValue) {
      if (newValue) {
        localStorage.currentChannel = JSON.stringify(newValue);
        this.$emit('change', this.getFilters());
      }
    },
    currentAgents(newValue) {
      if (newValue) {
        this.$emit('change', this.getFilters());
      }
    },
  },
  mounted() {
    if (this.companies.length > 0) {
      this.autoSelectCompany(this.companies);
    }
  },
  methods: {
    resetValues() {
      localStorage.currentChannel = null;
      this.currentChannel = null;
      this.currentAgents = [];
    },
    toggle() {
      this.isVisible = !this.isVisible;
    },
    onClose() {
      this.isVisible = false;
    },
    middleware(event) {
      return !!this.$refs.filterBox && !this.$refs.filterBox.contains(event.target);
    },
    rangeChanged(startDate, endDate) {
      this.currentStartDate = startDate;
      this.currentEndDate = endDate;
      this.$emit('change', this.getFilters());
    },
    getFilters() {
      const filters = {
        startDate: this.$moment(this.currentStartDate)
          .format('YYYY-MM-DD'),
        endDate: this.$moment(this.currentEndDate)
          .format('YYYY-MM-DD'),
        companyId: this.currentCompanyId,
        campaignId: this.currentCampaignId,
        channelId: this.currentChannelId,
      };

      if (this.currentAgents.length > 0) {
        filters.agentId = this.currentAgents.map(agent => agent.id);
      }

      return filters;
    },
    getCompanyById(id) {
      if (this.fullCompanies) {
        return this.fullCompanies.find(company => parseInt(id, 10) === parseInt(company.id, 10));
      }

      return undefined;
    },
    campaignIsSelectable(campaignsList, campaign) {
      if (!campaign) {
        return false;
      }

      return campaignsList.find(c => parseInt(c.id, 10) === campaign.id) !== undefined;
    },
    channelIsSelectable(channelsList, channel) {
      if (!channel) {
        return false;
      }

      return channelsList.find(c => parseInt(c.id, 10) === channel.id) !== undefined;
    },
    autoSelectCompany(companiesList) {
      if (localStorage.currentCompany) {
        this.currentCompany = JSON.parse(localStorage.currentCompany);
      } else {
        const [firstCompany] = companiesList;
        this.currentCompany = firstCompany;
      }
    },
    filterLimit(count) {
      return `${count} ${this.$t('multiselect.limit-suffix')}`;
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/_button-filter.scss';
</style>
