<template>
  <div>
    <section class="main-content">
      <div
        v-show="isOwner"
        class="grid-x align-right margin-bottom--20">
        <div class="cell shrink">
          <button-filter
            v-bind:display="enabledFilters"
            v-on:change="onFiltersChanged"/>
        </div>
      </div>

      <div class="grid-x grid-margin-x grid-margin-y">
        <div
          v-for="campaign in campaigns"
          :key="campaign.id"
          class="cell medium-6">
          <campaign-card
            @edited="showEditForm"
            @updated="updateCampaign"
            @campaign-configuration-opened="openCampaignConfiguration"
            :is-owner="isOwner"
            :campaign="campaign"/>
        </div>

        <div class="cell medium-6">
          <campaign-card-create
            :is-owner="isOwner"
            @saved="createCampaign"/>
        </div>
      </div>
    </section>
    <campaign-edit-modal
      v-if="displayEdit"
      @save="saveEditForm"
      @close="hideEditForm"
      :campaign="editedCampaign"/>
  </div>
</template>

<script>
import jwtDecode from 'jwt-decode';
import { mapMutations, mapGetters } from 'vuex';
import { getQuery } from '@/utils/gql-api';
import EventBus from '@/eventBus';
import campaignsByCompanyIdQuery from '@/graphql/queries/campaigns/byCompanyId.gql';
import addCampaignMutation from '@/graphql/mutations/addCampaign.gql';
import editCampaignMutation from '@/graphql/mutations/editCampaign.gql';

export default {
  components: {
    ButtonFilter: () => import('@/components/ButtonFilter.vue'),
    CampaignCard: () => import('@/components/CampaignCard.vue'),
    CampaignCardCreate: () => import('@/components/CampaignCardCreate.vue'),
    CampaignEditModal: () => import('@/components/CampaignEditModal.vue'),
  },
  data() {
    return {
      campaigns: [],
      filters: null,
      enabledFilters: ['company'],
      displayEdit: false,
      editedCampaign: null,
    };
  },
  computed: {
    currentCompanyId() {
      return this.filters ? this.filters.companyId : 0;
    },
    ...mapGetters({
      accessToken: 'dashboard/getAccessToken',
    }),
    decodedData() {
      let decoded;

      try {
        decoded = jwtDecode(this.accessToken);
      } catch (e) {
        decoded = {};
      }

      return decoded;
    },
    role() {
      return this.decodedData && this.decodedData.role ? this.decodedData.role : '';
    },
    isOwner() {
      return this.role === 'owner';
    },
  },
  watch: {
    filters(newValue, oldValue) {
      if (newValue !== null && (oldValue === null || newValue.companyId !== oldValue.companyId)) {
        this.loadCampaigns();
      }
    },
  },
  mounted() {
    EventBus.$on('UPDATE_CAMPAIGN', (data) => {
      this.updateCampaign(data);
    });

    if (this.filters !== null) {
      this.loadCampaigns();
    }
  },
  methods: {
    ...mapMutations({
      setCurrentCampaign: 'setting/setCurrentCampaign',
      clearCurrentCampaign: 'setting/clearCurrentCampaign',
    }),
    onFiltersChanged(filters) {
      this.filters = filters;
    },
    async loadCampaigns() {
      const result = await getQuery(campaignsByCompanyIdQuery, {
        companyId: this.currentCompanyId,
      }, 'campaignsByCompanyId');

      this.campaigns = result.map(el => ({
        ...el,
        loading: false,
      }));
    },
    async createCampaign(data) {
      const length = this.campaigns.push({ isBlank: true });

      const result = await this.$apollo.mutate({
        mutation: addCampaignMutation,
        variables: {
          ...data,
          company: this.currentCompanyId,
        },
      });

      this.$set(this.campaigns, length - 1, result.data.addCampaign);
    },
    async updateCampaign(data) {
      this.showLoadingCard(data.id);

      const result = await this.$apollo.mutate({
        mutation: editCampaignMutation,
        variables: data,
      });

      this.refreshCard(result.data.editCampaign);
      this.hideLoadingCard(data.id);
    },
    showLoadingCard(id) {
      const index = this.getCampaignIndex(id);

      if (index > -1) {
        this.campaigns[index].loading = true;
      }
    },
    hideLoadingCard(id) {
      const index = this.getCampaignIndex(id);

      if (index > -1) {
        this.campaigns[index].loading = false;
      }
    },
    refreshCard(data) {
      const index = this.getCampaignIndex(data.id);

      if (index > -1) {
        const { loading } = this.campaigns[index];

        this.$set(this.campaigns, index, {
          ...data,
          loading,
        });

        this.setCurrentCampaign(data);
      }
    },
    getCampaignIndex(id) {
      return this.campaigns.findIndex(c => c.id === id);
    },
    showEditForm(id) {
      this.editedCampaign = this.campaigns.find(c => c.id === id);
      this.displayEdit = true;
    },
    saveEditForm(data) {
      this.hideEditForm();
      this.updateCampaign(data);
    },
    hideEditForm() {
      this.editedCampaign = null;
      this.displayEdit = false;
    },
    openCampaignConfiguration(id) {
      const selected = this.campaigns.find(c => c.id === id);
      this.setCurrentCampaign(selected);
      this.$router.push(`/campaigns/${id}/settings`);
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/views/_campaigns.scss';
</style>
