<template>
  <div>
    <section class="main-content templates-container">
      <alert />
      <div class="grid-x grid-margin-x">
        <div class="cell sidebar large-3 select-client">
          <client-list v-on:ondelete="onDeleteClient" />
        </div>
        <div v-show="step === 1" class="cell large-9 select-table">
          <div class="grid-x">
            <div class="search-container">
              <input
                type="text"
                v-model="search"
                @keyup.enter="submit"
                :placeholder="$t('templates.search')"
              >
              <div class="action-buttons">
                <upload-file-csv v-show="showCsv" @load="getNumbers" />
                <button-filter :display="showDisplay" v-on:change="onFiltersChanged"/>
              </div>
            </div>
          </div>
          <data-table-conversation
            :headers="headers"
            :items="data"
            :check="true"
            :paginationOptions="paginationOptions"
            v-on:change-page="changePage"
          />
        </div>
        <div v-if="step === 2" class="columns large-9">
          <div>
            <div class="heading-step-2">{{ $t('templates.view') }}</div>
            <div class="sidebar preview-content">
              <div class="grid-x">
                <div class="large-6 preview-template">
                  <div class="header-preview">{{ $t('templates.chat') }}</div>
                  <div class="preview-text">
                    {{ parseText }}
                  </div>
                  <div class="time-text">
                    {{ today }}
                  </div>
                </div>
                <div class="large-6 input-labels">
                  <div class="heading-labels">{{ $t('templates.params') }}</div>
                  <div v-for="(param, i) in paramsForm" :key="i">
                    <p v-if="selectLabels && selectLabels[i]">
                      Seleccionaste la etiqueta de
                      <span class="select-label">{{ selectLabels[i] }}</span>
                      <a
                        href="#"
                        class="delete-label"
                        @click="deleteLabel(i)"
                      >
                        Eliminar
                      </a>
                    </p>
                    <div class="display-flex">
                      <input
                        type="text"
                        :placeholder="'Parámetro '+ (i+1)"
                        v-model.trim="params[i]"
                      >
                      <menu-dropdown
                        :labels="labels"
                        :input="i"
                        :label-select="selectLabels[i]"
                        v-on:select-label="selectedLabel"
                      />
                    </div>
                    <div class="label-note" v-if="selectLabels && selectLabels[i]">
                      *Debes poner un valor por default en caso de que no se encuentre la etiqueta.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <available-templates v-show="step === 1" :items="templates" />
      <div class="row">
        <div class="columns button-actions">
            <button
              class="button primary lg next-button"
              v-show="step === 1"
              :disabled="validStep1"
              @click="next"
            >
              {{ $t('templates.next') }}
            </button>
        </div>
        <div class="secondary-actions">
          <div class="back-button">
            <button
              class="button outline lg"
              v-show="step === 2"
              @click="prev"
            >
              {{ $t('templates.prev') }}
            </button>
          </div>
          <div class="send-button">
            <button
              class="button primary lg"
              v-show="step === 2"
              :disabled="btnDisabled"
              @click="send"
            >
              {{ $t('templates.send') }}
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import DataTableConversation from '@/components/DataTableConversation.vue';
import ClientList from '@/components/ClientList.vue';
import AvailableTemplates from '@/components/AvailableTemplates.vue';
import ButtonFilter from '@/components/ButtonFilter.vue';
import Alert from '@/components/Alert.vue';
import MenuDropdown from '@/components/MenuDropdown.vue';
import UploadFileCsv from '@/components/UploadFileCsv.vue';
import listClientsGql from '@/graphql/queries/listClients.gql';
import listTemplatesGql from '@/graphql/queries/listTemplates.gql';
import campaingByIdGql from '@/graphql/queries/campaigns/byId.gql';
import { getQuery } from '@/utils/gql-api';
import { onLogout } from '@/vue-apollo';
import api from '@/api';

const redirectUrl = process.env.VUE_APP_REDIRECT_URL ?? 'https://app.heycenter.com';

export default {
  components: {
    ClientList,
    DataTableConversation,
    AvailableTemplates,
    ButtonFilter,
    Alert,
    MenuDropdown,
    UploadFileCsv,
  },
  data() {
    return {
      showDisplay: ['company', 'campaign', 'channel'],
      filters: null,
      currentCampaignId: null,
      currentChannelId: null,
      headers: [
        {
          text: this.$t('templates.table.headers.number'),
          value: 'number',
        },
        {
          text: this.$t('templates.table.headers.name'),
          value: 'name',
        },
        {
          text: this.$t('templates.table.headers.lastconection'),
          value: 'lastconection',
        },
        {
          text: this.$t('templates.table.headers.tags'),
          value: 'tags',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
      templates: [],
      data: [],
      paginationOptions: {
        pageTotal: 0,
        page: 1,
        perPage: 10,
      },
      search: null,
      step: 1,
      params: [],
      btnDisabled: false,
      maxSendTemplate: 1000,
      labels: [],
      selectLabels: [],
      showCsv: false,
      csv: null,
    };
  },
  computed: {
    ...mapState({
      clientList: state => state.template.clientList,
      templateSelected: state => state.template.templateSelected || null,
    }),
    paramsForm() {
      if (this.templateSelected
        && this.templateSelected.settings
        && this.templateSelected.settings.num_params) {
        return parseInt(this.templateSelected.settings.num_params, 10);
      }
      return 0;
    },
    parseText() {
      let { text } = this.templateSelected;
      this.params.forEach((param, index) => {
        const re = `{{${index + 1}}}`;
        text = text.replace(re, param);
      });
      return text;
    },
    today() {
      return this.$moment(new Date())
        .format('hh:mm A');
    },
    validStep1() {
      if (this.clientList && this.clientList.length !== 0 && this.templateSelected) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapMutations({
      toogleClientList: 'template/toogleClientList',
      toogleAvailableTemplate: 'template/toogleAvailableTemplate',
      deleteClientsList: 'template/deleteClientsList',
      setAlert: 'template/setAlert',
      setClientList: 'template/setClientList',
    }),
    reset() {
      this.data = [];
      this.templates = [];
      this.paginationOptions.pageTotal = 0;
      this.paginationOptions.page = 1;
      this.search = null;
      this.showCsv = false;
    },
    async onFiltersChanged({ campaignId, channelId }) {
      this.reset();
      this.deleteClientsList([]);
      if (campaignId && channelId) {
        this.showCsv = true;
        this.currentCampaignId = campaignId;
        this.currentChannelId = channelId;
        this.getConversations(
          this.currentCampaignId,
          this.currentChannelId,
          this.paginationOptions.page,
        );
        this.getTemplates();
        this.getLabels();
      }
    },
    async getTemplates() {
      this.toogleAvailableTemplate(true);
      this.templates = await getQuery(listTemplatesGql, {
        campaign_id: this.currentCampaignId,
        status: 'active',
      }, 'listTemplates');
      this.toogleAvailableTemplate(false);
    },
    async getConversations(campaignId, channelId, page, search = null) {
      this.toogleClientList(true);
      const { data, total } = await getQuery(listClientsGql, {
        campaign_id: campaignId,
        channel_id: channelId,
        page,
        perPage: this.paginationOptions.perPage,
        search,
      }, 'listClients');
      this.paginationOptions.pageTotal = total;
      this.data = data;
      this.toogleClientList(false);
    },
    async getLabels() {
      const campaing = await getQuery(campaingByIdGql, {
        id: this.currentCampaignId,
      }, 'campaign');
      if (campaing) {
        this.labels = campaing.labels;
      }
    },
    changePage(page) {
      if (this.paginationOptions.page !== page) {
        this.paginationOptions.page = page;
        this.getConversations(
          this.currentCampaignId,
          this.currentChannelId,
          page,
        );
      }
    },
    submit() {
      this.data = [];
      this.paginationOptions.pageTotal = 0;
      this.paginationOptions.page = 1;
      this.getConversations(
        this.currentCampaignId,
        this.currentChannelId,
        this.paginationOptions.page,
        this.search,
      );
    },
    next() {
      this.step = 2;
    },
    prev() {
      this.params = [];
      this.selectLabels = [];
      this.step = 1;
    },
    send() {
      this.btnDisabled = true;
      if (this.params.length !== this.paramsForm) {
        this.setAlert({
          status: true,
          type: 'error',
          msg: this.$t('alerts.allRequiredFields'),
        });
        this.btnDisabled = false;
        return;
      }
      const clients = this.clientList.map(client => client.username);
      if (clients.length > this.maxSendTemplate) {
        this.setAlert({
          status: true,
          type: 'error',
          msg: this.$t('alerts.maxTemplate'),
        });
        this.btnDisabled = false;
        return;
      }
      const { params } = this;
      const { selectLabels } = this;
      if (selectLabels && selectLabels.length > 0) {
        selectLabels.forEach((tag, i) => {
          if (tag) {
            params[i] = `TAG:${tag}|${params[i]}`;
          }
        });
      }
      const data = {
        template: this.templateSelected.id,
        channel: this.currentChannelId,
        clients,
        params,
      };
      this.btnDisabled = false;
      api.templateSend(data)
        .then((res) => {
          if (res.data && res.data.status) {
            this.prev();
            this.deleteClientsList([]);
            this.setAlert({
              status: true,
              type: 'info',
              msg: this.$t('alerts.inProcess'),
            });
          } else {
            this.setAlert({
              status: true,
              type: 'error',
              msg: this.$t('alerts.oops'),
            });
          }
          this.btnDisabled = false;
        })
        .catch((e) => {
          if (e.response.status === 401) {
            onLogout(this.$apollo.provider.defaultClient);
            window.location.href = redirectUrl;
          }
          this.setAlert({
            status: true,
            type: 'error',
            msg: this.$t('alerts.unexpectedError'),
          });
          this.btnDisabled = false;
        });
    },
    onDeleteClient() {
      if (this.clientList.length === 0) {
        this.prev();
      }
    },
    selectedLabel(item) {
      this.selectLabels[item.input] = null;
      this.selectLabels.splice(item.input, 1, item.label);
    },
    deleteLabel(index) {
      this.selectLabels.splice(index, 1, null);
    },
    getNumbers(e) {
      this.csv = e.split(/\r?\n/);
      const re = new RegExp(/(\+)?[0-9]{10,15}/);
      const clients = this.csv.filter(number => re.exec(number))
        .map(number => ({
          id: null,
          fullName: null,
          username: number,
          lastConversation: null,
          type: 'csv',
          labels: [],
        }));
      this.setClientList(clients);
      let msg = `Se encontraron ${clients.length} números válidos `;
      if (clients.length !== this.csv.length) {
        const numberInvalid = this.csv.length - clients.length;
        msg += `y ${numberInvalid} números no son válidos.`;
      }
      this.setAlert({
        status: true,
        type: 'info',
        msg,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@include foundation-button;
.sidebar {
  background: #fff;
  border: 1px solid #ededed;
}
@import '~styles/views/_sendTemplates.scss';

</style>
