import apolloProvider from '@/apollo';
import { onLogout } from '@/vue-apollo';

const redirectUrl = process.env.VUE_APP_REDIRECT_URL ?? 'https://app.heycenter.com';

const apollo = apolloProvider.defaultClient;

async function executeQuery(id, query, variables) {
  try {
    const { data: result } = await apollo.query({
      query,
      variables,
    });
    return result[id] || [];
  } catch (e) {
    console.error(e);
    if (e.networkError.statusCode === 401) {
      onLogout(apollo);
      window.location.href = redirectUrl;
    }
  }

  return [];
}

async function getQuery(query, variables, nameData) {
  try {
    const { data: result } = await apollo.query({
      query,
      variables,
    });
    return result[nameData] || [];
  } catch (e) {
    console.error(e);
    if (e.networkError.statusCode === 401) {
      onLogout(apollo);
      window.location.href = redirectUrl;
    }
  }

  return [];
}

export { executeQuery, getQuery };
