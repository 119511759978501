<template>
  <div>
    <label class="button outline primary text-reader">Subir CSV
      <input
        class="button transparent"
        type="file"
        accept=".csv,
        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
        application/vnd.ms-excel"
        @change="loadTextFromFile"
      >
    </label>
  </div>
</template>

<script>
export default {
  methods: {
    loadTextFromFile(ev) {
      const file = ev.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.$emit('load', e.target.result);
      reader.readAsText(file);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~styles/components/_upload-file-csv.scss';
</style>
