<template>
  <div>
    <table-loader v-if="loaderClientList" />
    <template v-else>
      <table v-show="!isDataValid" class="container-table hover">
        <thead>
          <tr>
            <td class="check-all" v-if="check">
              <input
                type="checkbox"
                :value="paginationOptions.page"
                v-model="checkAll"
                @click="triggerSelectedAll"
              >
            </td>
            <th
              v-for="(header, index) in headers"
              :key="index"
              class="header"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="index"
            class="row-table"
          >
            <td v-if="check" @click="triggerCheckbox(item)">
              <input type="checkbox" :value="item" v-model="selected">
            </td>
            <td @click="triggerCheckbox(item)">{{ item.username }}</td>
            <td class="table-name" @click="triggerCheckbox(item)">{{ item.fullName }}</td>
            <td
              class="last-conection"
              @click="triggerCheckbox(item)"
            >
              {{ item.lastConversation }}
            </td>
            <td class="tags">
              <template v-if="item.labels && item.labels.length">
                <template v-for="(label, i) in item.labels">
                  <span
                    v-if="label.key && label.value && i < 3"
                    v-tooltip="label.value"
                    :key="i"
                  >
                    {{ label.key }}
                  </span>
                </template>
              </template>
              <template v-else>
                <span class="without-tags">{{ $t('templates.table.tags') }}</span>
              </template>
              <template v-if="item.labels.length > 3">
                <a href="javascript:void(0);" @click="openModalTags(item)"></a>...
              </template>
            </td>
            <td>
              <eye-icon @click="openModalTags(item)" />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <pagination
      v-show="!isDataValid"
      :items="items"
      :total-pages="paginationOptions.pageTotal"
      :page-size="paginationOptions.perPage"
      @changePage="onChangePage"
    />
    <div v-if="isDataValid" class="grid-x no-data-text">
      <p class="align-justify">{{ $t('templates.table.nodata') }}</p>
    </div>
    <modal-template
      v-if="modalTags"
      :item="itemCurrent"
      v-on:close="closeModal"
      v-on:trigger="triggerCheckbox"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import TableLoader from '@/components/loaders/Table.vue';
import Pagination from '@/components/Pagination.vue';
import ModalTemplate from '@/components/ModalTemplate.vue';

export default {
  components: {
    TableLoader,
    Pagination,
    ModalTemplate,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    check: {
      type: Boolean,
      required: false,
      default: false,
    },
    paginationOptions: {
      type: Object,
      required: false,
      default: () => ({
        pageTotal: 1,
        page: 1,
        perPage: 25,
      }),
    },
  },
  data() {
    return {
      showLoading: true,
      pages: [],
      modalTags: false,
      itemCurrent: [],
      checkAll: [],
    };
  },
  computed: {
    ...mapState({
      loaderClientList: state => state.template.loaderClientList,
    }),
    selected: {
      get() {
        return this.$store.state.template.clientList;
      },
      set(value) {
        this.setClientList(value);
      },
    },
    isDataValid() {
      return !this.loaderClientList && this.items && this.items.length === 0;
    },
  },
  methods: {
    ...mapMutations({
      setClientList: 'template/setClientList',
      deleteClientList: 'template/deleteClientList',
    }),
    onChangePage(page) {
      if (page !== this.paginationOptions.page) {
        this.$emit('change-page', page);
      }
    },
    triggerCheckbox(item) {
      this.addSelected(item);
      this.closeModal();
    },
    openModalTags(item) {
      if (item) {
        this.modalTags = true;
        this.itemCurrent = item;
      }
    },
    closeModal() {
      this.modalTags = false;
      this.itemCurrent = [];
    },
    addSelected(item) {
      const index = this.selected.findIndex(select => select.id === item.id);
      if (index === -1) {
        this.selected = [...this.selected, item];
      }
    },
    triggerSelectedAll(e) {
      this.items.forEach((item) => {
        if (e.target.checked) {
          this.addSelected(item);
        } else {
          this.deleteClientList(item.id);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/_data-table-conversation.scss';
</style>
