<template>
  <div class="cell sidebar large-6 select-client">
    <div class="header">
        <div class="text-header">Agentes seleccionados</div>
        <div v-show="agentsAssignList && agentsAssignList.length > 0" class="delete-all">
          <button @click="deleteAgents">
            borrar todo
          </button>
        </div>
    </div>
    <div class="global" v-show="agentsAssignList && agentsAssignList.length > 0">
      <div class="text-global">Asignar canales a todos:</div>
      <multiselect
        v-model="global"
        v-bind:options="globalOptions"
        v-bind:searchable="true"
        v-bind:close-on-select="false"
        v-bind:show-labels="false"
        v-bind:multiple="true"
        v-bind:placeholder="filterPlaceholder"
        v-bind:limit-text="filterLimit"
        v-bind:limit="1"
        track-by="name"
        label="name">
        <template slot="noResult">{{ $t('multiselect.no-result') }}</template>
        <template slot="noOptions">{{ $t('multiselect.no-options') }}</template>
      </multiselect>
    </div>

    <div class="clients-list">
      <template v-if="agentsAssignList && agentsAssignList.length > 0">
          <div
            class="grid-x list-item"
            v-for="agent in agentsAssignList"
            :key="agent.id"
          >
            <div class="cell small-1">
              <button @click="deleteAgent(agent.id)">X</button>
            </div>
            <div class="cell small-4 user-name">
              {{ agent.name }}
            </div>
            <div class="cell small-7 user-name">
              <multiselect
                v-model="agent.channelsAssing"
                v-bind:options="channels"
                v-bind:searchable="true"
                v-bind:close-on-select="false"
                v-bind:show-labels="false"
                v-bind:multiple="true"
                v-bind:placeholder="filterPlaceholder"
                v-bind:limit-text="filterLimit"
                v-bind:limit="1"
                track-by="name"
                label="name">
                <template slot="noResult">{{ $t('multiselect.no-result') }}</template>
                <template slot="noOptions">{{ $t('multiselect.no-options') }}</template>
              </multiselect>
            </div>
        </div>
      </template>
      <template v-else>
        <div class="select-empty">
          Selecciona a los agentes
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  components: {
    Multiselect: () => import('vue-multiselect'),
  },
  data() {
    return {
      placeholder: 'Canales',
      agents: [],
      global: null,
      globalOptions: [],
    };
  },
  mounted() {
    this.globalOptions = Object.assign([], this.channels);
    this.globalOptions.unshift({ id: 0, name: 'Todos' });
  },
  computed: {
    ...mapState({
      channels: state => state.setting.channels,
      agentsAssignList: state => state.setting.agentsAssignList,
    }),
    filterPlaceholder() {
      return this.placeholder !== '' ? this.placeholder : this.$t('multiselect.placeholder');
    },
  },
  watch: {
    global(newValue) {
      if (newValue) {
        const all = newValue.filter(channel => channel.name === 'Todos');
        let newAssign = Object.assign([], this.agentsAssignList);
        if (all && all.length) {
          newAssign = newAssign.map((agent) => {
            const data = Object.assign({}, agent);
            data.channelsAssing = this.channels;
            return data;
          });
        } else {
          newAssign = newAssign.map((agent) => {
            const data = Object.assign({}, agent);
            data.channelsAssing = newValue;
            return data;
          });
        }
        this.setAgentsAssignList(newAssign);
      }
    },
  },
  methods: {
    ...mapMutations({
      deleteAssignUser: 'setting/deleteAssignUser',
      deleteAssignUsers: 'setting/deleteAssignUsers',
      setAgentsAssignList: 'setting/setAgentsAssignList',
    }),
    deleteAgent(id) {
      this.deleteAssignUser(id);
    },
    deleteAgents() {
      this.global = null;
      this.deleteAssignUsers();
      this.$emit('ondelete');
    },
    filterLimit(count) {
      return `${count} ${this.$t('multiselect.limit-suffix')}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~styles/components/settings/_assign-user.scss';
</style>
