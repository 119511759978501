<template>
  <div>
    <section class="main-content">
      <tabs>
        <tab
          v-for="(tab, index) in tabs"
          :key="index"
          :icon="tab.icon"
          :name="tab.title"
          :selected="tab.selected">
          <component
            :is="tab.component"
            :campaign="campaign"></component>
        </tab>
      </tabs>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    CategoriesTab: () => import('@/components/settings/CategoriesTab.vue'),
    DataBankTab: () => import('@/components/settings/DataBankTab.vue'),
    LabelsTab: () => import('@/components/settings/LabelsTab.vue'),
    MessagesTab: () => import('@/components/settings/MessagesTab.vue'),
    OptionsTab: () => import('@/components/settings/OptionsTab.vue'),
    ScheduleTab: () => import('@/components/settings/ScheduleTab.vue'),
    UsersTab: () => import('@/components/settings/UsersTab.vue'),
    Tabs: () => import('@/components/TabsContainer.vue'),
    Tab: () => import('@/components/TabsSingle.vue'),
  },
  data() {
    return {
      tabs: [
        {
          icon: 'account-multiple-icon',
          title: 'Usuarios asignados',
          selected: true,
          component: 'users-tab',
        },
        {
          icon: 'palette-swatch-icon',
          title: 'Categorías',
          selected: false,
          component: 'categories-tab',
        },
        {
          icon: 'label-icon',
          title: 'Etiquetas',
          selected: false,
          component: 'labels-tab',
        },
        {
          icon: 'message-icon',
          title: 'Mensajes automáticos',
          selected: false,
          component: 'messages-tab',
        },
        {
          icon: 'clock-outline-icon',
          title: 'Horarios',
          selected: false,
          component: 'schedule-tab',
        },
        {
          icon: 'wrench-icon',
          title: 'Opciones',
          selected: false,
          component: 'options-tab',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      campaign: 'setting/getCurrentCampaign',
    }),
    campaignId() {
      return this.$route.params.campaign_id;
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/_campaign-configuration.scss';
</style>
