<template>
  <div>
    <catalog-loader v-if="loaderAvailableTemplate" />
    <div class="row select-templates" v-else>
      <div class="heading-templates">{{ $t('templates.availableTemplate') }}</div>
      <div class="columns shrink sidebar templates-choice">
        <p>{{ $t('templates.chooseTemplate') }}</p>
        <div v-if="items && items.length" class="grid-x grid-margin-x templates-wrapper">
          <template v-for="template in items">
            <div
              @click="setTemplateSelected(template)"
              :class="{ 'active': templateSelected && templateSelected.id === template.id}"
              class="template-card large-4"
              :key="template.id"
            >
              {{ template.text }}
            </div>
          </template>
        </div>
        <div v-else class="grid-x grid-margin-x templates-wrapper templates-empty">
          {{ $t('templates.noTemplate') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import CatalogLoader from '@/components/loaders/Catalog.vue';

export default {
  components: {
    CatalogLoader,
  },
  props: {
    items: {
      type: Array,
      require: true,
    },
  },
  computed: {
    ...mapState({
      loaderAvailableTemplate: state => state.template.loaderAvailableTemplate,
      templateSelected: state => state.template.templateSelected,
    }),
  },
  methods: {
    ...mapMutations({
      setTemplateSelected: 'template/setTemplateSelected',
    }),
  },
};
</script>
