<template>
  <div>
    <section class="main-content templates-container">
      <div class="grid-x margin-bottom--20">
        <div class="cell small-12 medium-3">
          <h2 class="heading heading-2">{{ details.title }}</h2>
          <p class="date">
            {{ $t('notification.send-date') }} {{ formatDatetime(details.createdAt) }}
          </p>
          <json-excel
            class="button outline accent"
            :data="json_data"
            :encoding="json_meta"
            worksheet="Templates"
            :name="titleExcel"
          >
            {{ $t('notification.download-excel') }}
          </json-excel>
        </div>
        <div class="cell small-12 medium-9">
          <p class="text" v-html="details.message" />
        </div>
      </div>
      <div class="grid-x">
        <div
          v-for="(item, i) in details.detail"
          :key="i"
          class="cell small-12 medium-6 large-3"
        >
          <div class="grid-x row-notify">
            <div class="cell small-11">
              <div class="phone">
                <img :src="require('@/assets/images/whatsapp_icon.svg')" alt="Whatsapp">
                {{ item.data.client }}
              </div>
              <div class="status">Estatus: <span>{{ status(item) }}</span></div>
            </div>
            <div class="cell small-1 d-flex">
              <component
                class="icon-status"
                :class="[ item.data.status === 'sended' ? 'green' : 'red']"
                v-bind:is="getIcon(item.data.status)"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import jsonExcel from 'vue-json-excel';

export default {
  components: {
    jsonExcel,
  },
  data() {
    return {
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    };
  },
  computed: {
    ...mapState({
      details: state => state.notification.details,
    }),
    json_data() {
      if (this.details && this.details.detail) {
        return this.details.detail.map(item => ({
          whatsapp: item.data.client,
          status: this.status(item),
        }));
      }
      return [];
    },
    titleExcel() {
      if (this.details) {
        return `${this.details.title}-${this.details.createdAt}.xls`;
      }
      return 'templates.xls';
    },
  },
  methods: {
    getIcon(status) {
      if (status === 'sended') {
        return 'check-circle';
      }
      return 'close-circle-icon';
    },
    formatDatetime(value) {
      if (!value) return '';
      return this.$moment(value)
        .format('DD/MM/YY');
    },
    status(value) {
      if (!value) return '';
      if (value.data.status === 'sended') {
        return 'Enviado';
      }
      if (value.data.status === 'error') {
        if (value.data.message && value.data.message === 'invalid.number') {
          return 'Número invalido.';
        } if (value.data.message && value.data.message.includes('429')) {
          return 'Rate limit hit';
        }
        return 'Error 500';
      }
      return '';
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/views/_notification.scss';
</style>
