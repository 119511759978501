<template>
  <div>
    <div class="header">
        <div class="text-header">{{ $t('templates.seletedClientes') }}</div>
        <div v-show="clientList && clientList.length > 0" class="delete-all">
            <button @click="deleteClients">
                {{ $t('templates.deleteClients') }}
            </button>
        </div>
    </div>

    <div class="clients-list">
      <template v-if="clientList && clientList.length > 0">
          <div
            :class="{ 'csv' : client.type && client.type === 'csv' }"
            class="list-item"
            v-for="client in clientList"
            :key="client.id"
          >
              <div class="phone-icon">
                  <img :src="require('@/assets/images/whatsapp_icon.svg')" alt="Calendar">
              </div>
              <div class="user-name">
                {{ client.username}}
              </div>
              <button @click="deleteClient(client.id)">X</button>
        </div>
      </template>
      <template v-else>
        <div class="select-empty">
          {{ $t('templates.chooseClients') }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState({
      clientList: state => state.template.clientList,
    }),
  },
  methods: {
    ...mapMutations({
      deleteClientList: 'template/deleteClientList',
      deleteClientsList: 'template/deleteClientsList',
    }),
    deleteClient(id) {
      this.deleteClientList(id);
      this.$emit('ondelete');
    },
    deleteClients() {
      this.deleteClientsList([]);
      this.$emit('ondelete');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~styles/components/_client-list.scss';
</style>
